import { Inject, Injectable } from '@angular/core'
import { UserServiceJwtV2Service as UserService } from '../../generated/openapi/userservice'
import { DynamicRuleServiceService as DynamicRuleService, ResponseBoolean } from '../../generated/openapi/berechtigungstool'
import { Router } from '@angular/router'
import { catchError, firstValueFrom, Observable, throwError } from 'rxjs'
import { EnvironmentService } from './environment.service'
import { KeycloakService } from 'keycloak-angular'
import { DOCUMENT } from '@angular/common'
import { map, shareReplay } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { tUser } from '../types'
import { cookieCreate } from '../utils'

export type ExternalTarget = {
  target: string,
  partner: string,
  ts: string
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private updateTokenInterval: string | number | NodeJS.Timeout | undefined
  private user$: Observable<tUser> = new Observable<tUser>()

  constructor(
    public router: Router,
    private http: HttpClient,
    private userService: UserService,
    private dynamicRuleService: DynamicRuleService,
    private envService: EnvironmentService,
    private keycloak: KeycloakService,
    @Inject(DOCUMENT) private document: Document
  ) {
    // empty
  }

  async getUser(): Promise<tUser> {
    return firstValueFrom(this.user$)
  }

  async getSSOId(apiUrl: string): Promise<string> {
    const data = await firstValueFrom(this.http.get<{
      'x-compeople-ssoid': string,
      'x-zob-ssoid': string
    }>(apiUrl, {
      headers: {
        'Authorization': 'Bearer ' + await this.keycloak.getToken()
      }
    }))

    return data["x-compeople-ssoid"]
  }

  async getConfig() {
    const config = await this.envService.getConfig()
    const keycloakInstance = this.keycloak.getKeycloakInstance()

    this.dynamicRuleService.configuration.basePath = config.apis.berechtigungsToolUrl
    this.dynamicRuleService.configuration.credentials = {
      'Bearer Authentication': keycloakInstance.token || ''
    }
    this.userService.configuration.basePath = config.apis.userServiceUrl
    this.userService.configuration.credentials = {
      'Bearer Authentication': keycloakInstance.token || ''
    }

    const tokenParsed = keycloakInstance.tokenParsed
    const context = tokenParsed!['context']
    const hasTextservicePermission: ResponseBoolean = await this.checkHasTextservicePermission(
      tokenParsed?.preferred_username
    )

    this.user$ = this.userService.requestUser()
      .pipe(map(user => {
        user.firstName = tokenParsed?.given_name
        user.lastName = tokenParsed?.family_name
        user.userId = tokenParsed?.preferred_username

        /* user_types: no - there is no number 7
          {1:"AUSSENDIENST", 2:"INNENDIENST", "3:MITBENUTZER", 4:"HOTLINE", 5:"AUTOTEST", 6:"KONZERN_MITARBEITER", 8:"ASSISTENT", 9:"SERVICE_ACCOUNT"}
         */
        return {
          ...user, ...{
            isAssistent: tokenParsed?.user_typ === '8',
            isVertreter: !!(tokenParsed?.user_typ !== '8' && context && context.prefered_username != tokenParsed?.preferred_username),
            context: {name: context?.family_name, vorname: context?.given_name},
            isInnendienst: tokenParsed?.user_typ === '2',
            isEditor: hasTextservicePermission.data || false
          }
        }
      })).pipe(shareReplay())
  }

  private async checkHasTextservicePermission(userId: string): Promise<ResponseBoolean> {
    return firstValueFrom(this.dynamicRuleService.calcBoolean({
      userid: userId,
      app: 'textservice',
      element: 'meinedvag'
    })).catch((e: Error) => {
      return {data: false}
    })
  }

  async initializeKeycloak() {
    // no authentication when health page is called
    if (this.document.location.hash.includes('health')) {
      return
    }

    const config = await this.envService.getConfig()

    try {
      const authenticated = await this.keycloak.init({
        config: {
          url: config.apis.keycloak,
          realm: config.apis.keycloakRealm,
          clientId: config.apis.keycloakClientId
        },
        initOptions: {
          onLoad: 'login-required',
          checkLoginIframe: true,
          checkLoginIframeInterval: 1000,
          silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html'
        }
      })

      if (authenticated) {
        this.checkIfRedirected()
        await this.getConfig()
        this.setSSOCookie(await this.getSSOId(config.apis.ssoInfoUrl))
        this.loadBefoerderungspopup().then()

        // Check token validity every 10 seconds (10 000 ms) and, if necessary, update the token
        // Refresh token if it's valid for less than 60 seconds
        this.updateTokenInterval = setInterval(() => this.keycloak?.updateToken(60).catch(() => {
          this.keycloak?.clearToken()
        }), 60000)
      }
    } catch (reason) {
      console.log('Failed to retrieve access token: ' + reason)
    }
  }

  async loadBefoerderungspopup() {
    const config = await this.envService.getConfig()

    // load befoerderungs-popup script in head
    let scriptNode = this.document.createElement('script')
    scriptNode.src = config.apis.befoerderungsPopup
    scriptNode.type = 'text/javascript'
    scriptNode.defer = true
    this.document.getElementsByTagName('head')[0].appendChild(scriptNode)
  }

  checkIfRedirected() {
    const entry = sessionStorage.getItem('externalTarget')
    if (entry) {
      const {target} = <ExternalTarget>JSON.parse(entry)
      sessionStorage.removeItem('externalTarget')

      this.http.get(target, {
        headers: {Authorization: 'Bearer ' + this.keycloak.getKeycloakInstance().token || ''}
      })
        .pipe(catchError((err) => {
          console.log('doExternalCall: ', err)
          return throwError(() => new Error(`doExternalCall: ${err}`))
        }))
        .subscribe((data: Partial<{ url: string }>) => {
          if (data.url) {
            this.router.navigate([]).then(() => {
              this.doRedirect(<string>data.url)
            })
          }
        })
    }
  }

  doRedirect(url: string) {
    this.document.location.href = url
  }

  setSSOCookie(ssoid: string) {
    cookieCreate('x-compeople-ssoid', ssoid)
  }
}
