import { tUser } from './types'

export const getUserFullName = (user: tUser): string => {
  return `${user.firstName} ${user.lastName}`
}

export const getRepresentativesName = (user: tUser): string => {
  if (user.isVertreter && user.context?.vorname && user.context?.name) {
    return user.context.vorname + ' ' + user.context.name
  } else {
    return ''
  }
}

export const getAssistentName = (user: tUser): string => {
  if (user.isAssistent && user.context?.vorname && user.context?.name) {
    return user.context.vorname + ' ' + user.context.name
  } else {
    return ''
  }
}

export const formatDate = (date: number | string) => {
  let _date
  if (typeof date === 'string') {
    _date = new Date(date)
  } else {
    _date = new Date(date * 1000)
  }
  return new Intl.DateTimeFormat(
    'de',
    {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })
    .format(_date)
}

export const deviceMapper = (deviceIds: Array<number>): Array<{id: number, labelId: number, icon: string, selected: boolean}> => {
  let devices = [
    {
      id: 1,
      labelId: 105,
      icon: 'assets/img/icons/device_desktop-pc.svg',
      selected: false
    },
    {
      id: 2,
      labelId: 106,
      icon: 'assets/img/icons/device_tablet.svg',
      selected: false
    },
    {
      id: 3,
      labelId: 107,
      icon: 'assets/img/icons/device_handy.svg',
      selected: false
    }
  ]

  return devices.map(device => {
    if (deviceIds!.find(elem => elem === device.id)) {
      device.selected = true
    }

    return device
  })
}

export const cookieCreate = (name: string, value: string, days: number = 0) => {
  let maxAge: string
  maxAge = ''

  if (days > 0) {
    maxAge = '; Max-Age=' + (days * 24 * 60 * 60)
  }

  let sameSite: string
  sameSite = '; SameSite=Lax'

  if (document.location.protocol.match(/^https/)) {
    sameSite = '; SameSite=None; Secure'
  }

  document.cookie = name + '=' + value + maxAge + '; path=/' + sameSite
}
